body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.woff2') format('woff2');
  font-weight: Bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Heavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Extrabold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Black.woff2') format('woff2');
      font-weight: 1000;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Thin.woff2') format('woff2');
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy-HeavyItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: Italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: Italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: Italic;
  }

  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy-BoldItalic.woff2') format('woff2');
    font-weight: Bold;
    font-style: Italic;
  }
  
  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy-ExtraboldItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-ThinItalic.woff2') format('woff2');
      font-weight: 100;
      font-style: Italic;
  }


  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-BlackItalic.woff2') format('woff2');
      font-weight: 1000;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-SemiboldItalic.woff2') format('woff2');
      font-weight: 600;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-RegularItalic.woff2') format('woff2');
      font-weight: normal;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Heavy.woff2') format('woff2');
      font-weight: 800;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Extrabold.woff2') format('woff2');
      font-weight: 900;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Black.woff2') format('woff2');
      font-weight: 1000;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Thin.woff2') format('woff2');
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Light.woff2') format('woff2');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Medium.woff2') format('woff2');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Semibold.woff2') format('woff2');
      font-weight: 600;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-Bold.woff2') format('woff2');
      font-weight: Bold;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-HeavyItalic.woff2') format('woff2');
      font-weight: 800;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-LightItalic.woff2') format('woff2');
      font-weight: 300;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-MediumItalic.woff2') format('woff2');
      font-weight: 500;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-BoldItalic.woff2') format('woff2');
      font-weight: Bold;
      font-style: Italic;
  }

  @font-face {
      font-family: 'Gilroy';
      src: local('Gilroy'), url('./fonts/Gilroy-ExtraboldItalic.woff2') format('woff2');
      font-weight: 900;
      font-style: Italic;
  }


  